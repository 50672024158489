import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";


export const BarrackState = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        side: -1,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });


    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];

    const [action, setAction] = useState({
        ...props.state.actions[props.userId][0],
        idPlayer: props.userId,
        cardsIndex: []
    });

    function getSideSelect() {
        if (state.side === -1) {
            const sideNames = ["Haut", "Droite", "Bas", "Gauche"];
            return <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    [0, 1, 2, 3].map(s => <div key={"side-pannel-" + s} style={{margin: '12px'}}>
                        <Button onClick={() => setState(st => ({...st, side: s}))} variant="contained">
                            {sideNames[s]}
                        </Button>
                    </div>)
                }
            </div>
        }
    }

    function getCaseSelect() {
        if (state.side === -1) {
            return <div></div>
        }
        return <div>
            <h2>{["Haut", "Droite", "Bas", "Gauche"][state.side]}</h2>
            {
                actions.filter(a => a.class === "action.cases.ActionAddBarrack" && a.caseNumber > state.side * 10 && a.caseNumber < (state.side + 1) * 10).map(a =>
                    <div
                        key={"case_" + a.caseNumber}
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "row",
                            height: '100px',
                            margin: '12px 0',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                        {
                            <img src={"/public/heliopolis/case_" + a.caseType.toLowerCase() + ".png"}
                                 style={{height: '100%', width: 'auto'}}/>
                        }
                        <Button variant={"contained"} disabled={!a.ok}
                                onClick={() => setState(st => ({...st, action: a, show: true}))}>
                            Construire
                        </Button>
                    </div>)
            }
            <Button variant={"contained"} onClick={() => setState(st => ({...st, side: -1}))}>
                Retour
            </Button>
        </div>
    }

    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez vous l'action " + state.action.name}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                C'est à vous de jouer !
            </h2>
            <div>
                Poser une Caserne
            </div>
            {getSideSelect()}
            {getCaseSelect()}
            <div style={{margin: '12px'}}>
                <Button onClick={() => setState(st => ({...st, action: actions[actions.length - 1], show: true}))}
                        variant="contained">
                    Passer
                </Button>
            </div>
        </div>
    )

};

export default BarrackState;
