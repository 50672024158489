import React from "react";
import {Route, Switch} from "react-router";
import PrivateRoute from "./app/utils/PrivateRoute";
import VerifyEmail from "./app/utils/auth/VerifyEmail";
import ResetPassword from "./app/utils/auth/ResetPassword";
import ChangePassword from "./app/utils/auth/ChangePassword";
import Stats from "./app/pages/board-game/stats/Stats";
import LoginPage from "./app/utils/auth/LoginPage";
import LogoutPage from "./app/utils/auth/LogoutPage";
import SignupPage from "./app/utils/auth/SignupPage";
import ConfidentialityPolicy from "./app/pages/legal/ConfidentialityPolicy";
import LegalTerms from "./app/pages/legal/LegalTerms";
import LandingPage from "./app/pages/landing/LandingPage";
import {TopBar} from "./app/utils/TopBar/TopBar";
import KillingZeusPage from "./app/pages/killing-zeus/KillingZeusPage";
import UltraMythPage from "./app/pages/ultra-myth/UltraMythPage";
import BoardGamePage from "./app/pages/board-game/BoardGamePage";
import PreviousGameDetails from "./app/pages/board-game/PreviousGamePage";
import TerraformingHome from "./app/pages/board-game/games/terraforming/TerraformingHome";
import MegawattHome from "./app/pages/board-game/games/megawatt/MegawattHome";
import ThroughTheAgesHome from "./app/pages/board-game/games/throughTheAges/ThroughTheAgesHome";
import {AdminPage} from "./app/pages/admin/AdminPage";
import SevenWondersHome from "./app/pages/board-game/games/7wonders/SevenWondersHome";
import BottomBar from "./app/utils/BottomBar/BottomBar";
import HeliopolisHome from "./app/pages/board-game/games/heliopolis/HeliopolisHome";
import Anniv2024, {Anniv2024Page} from "./app/pages/anniv2024/Anniv2024";

// import Home from "../archive_src/Home";
// import PreviousGameDetails from "../archive_src/PreviousGameDetails";
// import SevenWondersMobile from "../archive_src/SevenWonders/mobile/SevenWondersMobile";
// import TerraformingHome from "../archive_src/terraforming/TerraformingHome";
// import MegawattHome from "../archive_src/megawatt/MegawattHome";
// import ThroughTheAgesHome from "../archive_src/throughTheAges/ThroughTheAgesHome";
// import SevenWondersHome from "../archive_src/7wonders/SevenWondersHome";

const routes = () => (
    <div>
            <Route path='/' component={TopBar}/>
            <Route path='/login' component={LoginPage}/>
            <Route path='/logout' component={LogoutPage}/>
            <Route path='/signup' component={SignupPage}/>
            <Route path='/verifyEmail' component={VerifyEmail}/>
            <Route path='/resetPassword' component={ResetPassword}/>
            <Route path='/changePassword' component={ChangePassword}/>
            <Route path='/confidentiality' exact={true} component={ConfidentialityPolicy}/>
            <Route path='/legalterms' exact={true} component={LegalTerms}/>
            {/*<PrivateRoute path='/sevenWonders' exact={true} component={SevenWondersMobile}/>*/}
            <Route path='/' exact={true} component={LandingPage}/>
            <Route path='/killing-zeus' exact={true} component={KillingZeusPage}/>
            <Route path='/ultra-myth' exact={true} component={UltraMythPage}/>
            <PrivateRoute path='/board-games' exact={true} component={BoardGamePage} boardGame={true}/>
            <PrivateRoute path='/board-games/previous-game/:gamekey/:gameId' exact={true} component={PreviousGameDetails} boardGame={true}/>
            <PrivateRoute path='/board-games/terraforming-mars/stats' exact={true} component={Stats} boardGame={true}/>
            <PrivateRoute path='/terraforming/:gameId' exact={true} component={TerraformingHome} boardGame={true}/>
            <PrivateRoute path='/megawatt/:gameId' exact={true} component={MegawattHome} boardGame={true}/>
            <PrivateRoute path='/throughTheAges/:gameId' exact={true} component={ThroughTheAgesHome} boardGame={true}/>
            <PrivateRoute path='/sevenWonders/:gameId' exact={true} component={SevenWondersHome} boardGame={true}/>
            <PrivateRoute path='/heliopolis/:gameId' exact={true} component={HeliopolisHome} boardGame={true}/>
            <PrivateRoute path='/admin' exact={true} component={AdminPage} admin={true}/>
            <Route path='/' component={BottomBar}/>
    </div>
);

export default routes;
