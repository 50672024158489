import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";


export const MarketState = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });


    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];
    const river = props.state.river;
    const [mode, setMode] = useState("buy");

    const [action, setAction] = useState({
        ...props.state.actions[props.userId][0],
        idPlayer: props.userId,
        cardsIndex: []
    });

    const [actionSell, setActionSell] = useState({
        ...props.state.actions[props.userId][1],
        idPlayer: props.userId,
        resourcesToSell: {
            WOOD: 0,
            PAPYRUS: 0,
            FER: 0
        }
    })

    function getTotal() {
        return action.cardsIndex.reduce((a, i) => a + i, 0);
    }

    function isOkSell() {
        console.log(actionSell.resourcesToSell);
        console.log(actions[1]);
        for (let a of actions) {
            if(a.class !== "action.cases.ActionStartSell"){
                continue;
            }
            let ok = true;
            for(let key of Object.keys(a.resourcesToSell)){
                if(key === "GOLD"){
                    continue;
                }
                if(a.resourcesToSell[key] !== actionSell.resourcesToSell[key]){
                    ok = false;
                    break;
                }
            }
            if(ok){
                return true;
            }
        }
        return false;
    }

    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez vous l'action " + state.action.name}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                Vous êtes au marché !
            </h2>
            <div style={{margin: '12px 0'}}>
                Que souhaitez-vous faire ?
            </div>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div onClick={() => setMode("buy")}
                     style={{
                         flex: 1,
                         display: 'flex',
                         width: "50%",
                         justifyContent: 'center',
                         alignItems: 'center',
                         margin: "20px 0",
                         background: mode === "buy" ? 'linear-gradient(to right, #201e4e 0%, #10346f 50%, #201e4e 100%)' : 'none'
                     }}
                >
                    Acheter
                </div>
                <div onClick={() => setMode("sell")}
                     style={{
                         flex: 1,
                         display: 'flex',
                         width: "50%",
                         justifyContent: 'center',
                         alignItems: 'center',
                         margin: "20px 0",
                         background: mode === "sell" ? 'linear-gradient(to right, #201e4e 0%, #10346f 50%, #201e4e 100%)' : 'none'
                     }}
                >
                    Vendre
                </div>
            </div>
            <div style={{margin: '12px 0'}}>
                {mode === "buy" ? "Acheter des cartes de la rivière" : "Vendre 10 ressources pour 5 points"}
            </div>
            {
                mode === "buy" ?
                    <div>
                        <div style={{display: 'flex', margin: '12px 0 36px 0', flexDirection: 'column'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '8px 0'}}>
                                Total des achats : {getTotal()}
                                <img src={"/public/heliopolis/resource_gold.png"} style={{marginLeft: '8px'}}
                                     height={"20px"}
                                     width={"20px"}/>
                            </div>
                            <Button variant="contained" disabled={getTotal() > action.money}
                                    onClick={() => setState(state => ({...state, action, show: true}))}>
                                Confirmer
                            </Button>
                        </div>
                        <div style={{
                            display: "-webkit-inline-box",
                            position: "absolute",
                            left: "10%",
                            overflow: "scroll",
                            width: "80%",
                        }}>
                            {
                                props.state.river.map((cardId, i) => <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '30vw',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                                                          key={cardId}>
                                    <img style={{width: '100%', height: 'auto'}}
                                         onClick={() => props.setCardId(cardId)}
                                         src={"/public/heliopolis/cards/card_" + cardId + ".png"}/>
                                    <div style={{
                                        width: '50%', backgroundColor: 'black', color: 'white', margin: '12px 0',
                                        height: '30px', borderRadius: '20px', display: 'flex',
                                        flexDirection: 'row', justifyContent: "center", alignItems: 'center'
                                    }}>
                                        {i}
                                        <img src={"/public/heliopolis/resource_gold.png"}
                                             style={{height: '30px', width: 'auto'}}/>
                                    </div>
                                    {
                                        action.cardsIndex.indexOf(i) > -1 ?
                                            <Button
                                                variant="contained"
                                                onClick={() => setAction(a => ({
                                                    ...a,
                                                    cardsIndex: a.cardsIndex.filter(o => o !== i)
                                                }))}>
                                                Enlever
                                            </Button> :
                                            <Button
                                                onClick={() => setAction(a => ({
                                                    ...a,
                                                    cardsIndex: [...a.cardsIndex, i]
                                                }))}>
                                                Prendre
                                            </Button>
                                    }
                                </div>)
                            }

                        </div>
                    </div> :
                    <div>
                        <div style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {
                                ["wood", "papyrus", "fer"].map(resource => <div key={resource}
                                                                                style={{
                                                                                    flex: 1,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    margin: "20px 0"
                                                                                }}
                                >
                                    <Button variant={"contained"} onClick={() => setActionSell(a => {
                                        const resourcesToSell = {...a.resourcesToSell};
                                        resourcesToSell[resource.toUpperCase()] -= 1;
                                        return {...a, resourcesToSell};
                                    })} style={{margin: "0 12px"}}
                                            disabled={actionSell.resourcesToSell[resource.toUpperCase()] === 0}>
                                        -
                                    </Button>
                                    {actionSell.resourcesToSell[resource.toUpperCase()]}
                                    <img style={{height: '40px', width: 'auto', marginLeft: '12px'}}
                                         src={"/public/heliopolis/resource_" + resource + ".png"}/>
                                    <Button variant={"contained"} onClick={() => setActionSell(a => {
                                        const resourcesToSell = {...a.resourcesToSell};
                                        resourcesToSell[resource.toUpperCase()] += 1;
                                        return {...a, resourcesToSell};
                                    })} style={{margin: "0 12px"}}
                                            disabled={actionSell.resourcesToSell[resource.toUpperCase()] === props.state.players[props.userId][resource]}>
                                        +
                                    </Button>
                                </div>)
                            }
                        </div>

                        <Button variant="contained"
                                disabled={!isOkSell()}
                                onClick={() => setState(state => ({...state, action: actionSell, show: true}))}>
                            Vendre
                        </Button>
                    </div>
            }
            <div style={{height: "75vh"}}>
            </div>
        </div>
    )

};

export default MarketState;
