import React, {useState} from "react";
import Box from "@mui/material/Box";


export const ConsultScreen = (props) => {

    const [consult, setConsult] = useState("Main");
    const [sortGod, setSortGod] = useState(undefined);

    function getGodSymbol(god) {
        return props.state.players[props.userId].badgesInHand[god.toUpperCase()] || 0;
    }
    const [sortPlayer, setSortPlayer] = useState(Object.keys(props.state.players)[0]);

    let compConsult = undefined;
    const consults = [
        "Main",
        "Rivière",
        "Bâtiments",
        "Règles"
    ];
    if (consult === "Main") {
        compConsult = <div>
            <h2>
                Votre main ({props.state.players[props.userId].hand.length})
            </h2>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    ["anubis", "bastet", "horus", "apophis"].map(god => <div key={god}
                                                                             onClick={() => setSortGod(sortGod => sortGod === god ? undefined : god)}
                                                                             style={{
                                                                                 flex: 1,
                                                                                 display: 'flex',
                                                                                 justifyContent: 'center',
                                                                                 alignItems: 'center',
                                                                                 margin: "20px 0",
                                                                                 background: sortGod === god ? 'linear-gradient(to right, #201e4e 0%, #10346f 50%, #201e4e 100%)': 'none'
                                                                             }}
                    >
                        {getGodSymbol(god)}
                        <img style={{height: '40px', width: 'auto'}} src={"/public/heliopolis/badge_" + god + ".png"}/>
                    </div>)
                }
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    props.state.players[props.userId].hand.map((cardId, i) =>
                        <img src={"/public/heliopolis/cards/card_" + cardId + ".png"}
                             onClick={() => props.setCardId(cardId)}
                             style={{width: '30%', height: 'auto', margin: "5px 1%"}} key={cardId}/>
                    )
                }
            </div>
        </div>

    } else if (consult === "Rivière") {
        compConsult = <div>
            <h2>
                La Rivière
            </h2>
            <div style={{display: "-webkit-inline-box", position: "absolute", left: "10%", overflow: "scroll", width:"80%"}}>
                {
                    props.state.river.map((cardId, i) => <div style={{display: 'flex', flexDirection: 'column', width: '30vw', justifyContent: 'center', alignItems: 'center'}}
                                                       key={cardId}>
                        <img style={{width: '100%', height: 'auto'}}
                             onClick={() => props.setCardId(cardId)}
                             src={"/public/heliopolis/cards/card_" + cardId + ".png"}
                        />
                        <div style={{
                            width: '50%', backgroundColor: 'black', color: 'white', marginTop: '24px',
                            height: '30px', borderRadius: '20px', display: 'flex',
                            flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                            {i}
                            <img src={"/public/heliopolis/resource_gold.png"} style={{height: '30px', width: 'auto'}}/>
                        </div>
                    </div>)
                }

            </div>
        </div>
    } else if (consult === "Bâtiments") {
        compConsult = <div>
            <h2>
                Les bâtiments construits
            </h2>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    Object.keys(props.state.players).map(playerId => <div key={playerId}
                                                                             onClick={() => setSortPlayer(sortPlayer => sortPlayer === playerId ? undefined : playerId)}
                                                                             style={{
                                                                                 flex: 1,
                                                                                 display: 'flex',
                                                                                 width: "50%",
                                                                                 justifyContent: 'center',
                                                                                 alignItems: 'center',
                                                                                 margin: "20px 0",
                                                                                 background: sortPlayer === playerId? 'linear-gradient(to right, #201e4e 0%, #10346f 50%, #201e4e 100%)': 'none'
                                                                             }}
                    >
                        {props.state.players[playerId].username}
                    </div>)
                }
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    props.state.players[sortPlayer].buildings.map((cardId, i) =>
                        <img src={"/public/heliopolis/cards/card_" + cardId + ".png"}
                             onClick={() => props.setCardId(() => cardId)}
                             style={{width: '30%', height: 'auto', margin: "5px 1%"}} key={cardId}/>
                    )
                }
            </div>
        </div>
    } else if (consult === "Règles") {
        const styleRule = {display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginBottom: "32px"};
        const styleRuleCase = {width: "50px", height: 'auto'};
        const styleRuleText = {flex: 1};
        const rules = [
            {img: "case_ruby", rule: "Vous pouvez acheter un Rubis : il coûte autant de Bronze que vous possédez déjà de Rubis. A la fin de la partie, gagnez des Points par personne ayant strictement moins de Rubis que vous."},
            {img: "case_prod", rule: "Déclenchez tous vos effets de cartes Production, vous pouvez ensuite choisir entre gagner 1 Bronze, 2 Papyrus ou 3 Bois"},
            {img: "case_temple", rule: "Vous pouvez payer 3 d'or pour progresser de 1 sur la piste de Foi et déclencher l'effet de la case d'arrivée. Lorsque vous arrivez au bout de la piste, vous revenez au départ, mais vous donne aussi un niveau de multiplicateur de foi, qui s'applique aux effets."},
            {img: "case_eclipse", rule: "Faîtes avancer l'eclipse de 1. Lorsque l'Eclipse est complète, tous les joueurs finissent leur tour en cours et la partie s'arrête."},
            {img: "case_market", rule: "Vous pouvez acheter autant de cartes de la Rivière que vous le souhaitez, en payant leur coût en or. Ces cartes arrivent dans votre main"},
            {img: "case_construction", rule: "Vous pouvez joueur une carte Bâtiment de votre main pour son coût"},
            {img: "case_barracks", rule: "Vous pouvez acheter une Caserne : elle coûte autant de Bois que vous possédez déjà de Caserne. Posez-la sur n'importe quelle case sauf celles dans les coins, et celle possédant déjà une caserne. Quand n'importe quel joueur, y compris vous, s'arrête sur une de vos Casernes, gagnez 2 Or et déclenchez vos effets de cartes Caserne"},
            {img: "case_sacrifice", rule: "Vous pouvez défausser 3 cartes aux symboles identiques ou 4 aux symboles différents pour progresser de 1 sur la piste du Sacrifice et gagner la récompense correspondante"},
            {img: "case_rejouer", rule: "Rejouez immédiatement"}
        ];
        const otherRules = [
            {img: "card-draw", rule: "Piochez la première carte de la pioche et ajoutez là à votre main gratuitement"},
            {img: "pick_river", rule: "Choisissez une carte de la rivière et ajoutez là à votre main gratuitement"},
            {img: "badge_anubis", rule: "Les symboles de vos cartes, défaussez-en 3 identiques ou 4 différents lors d'un sacrifice pour marquer des points"},
            {img: "resource_gold", rule: "L'Or, la ressource principale du jeu. Vous permet notamment d'acheter et de jouer des cartes ou de monter votre foi"},
            {img: "resource_wood", rule: "Le Bois, la moins chère des ressources secondaires. Vous permet notamment d'acheter des Casernes"},
            {img: "resource_papyrus", rule: "Le Papyrus, l'entre-deux des ressources secondaires. Vous permet notamment de piocher des cartes au début de votre tour"},
            {img: "resource_bronze", rule: "Le Bronze, la plus onéreuses des ressources secondaires. Vous permet notamment d'acheter des rubis"},
            {img: "victory", rule: "Les Points de Victoire, celui qui en a le plus à la fin du jeu remporte la partie. En cas d'égalité, on tranche avec l'Or, puis si l'égalité persiste, on utilise la somme des ressources secondaires"},
        ]
        compConsult = <div>
            <h2>
                À votre tour
            </h2>
            <div>
                Quand c'est votre tour, vous pouvez avancer de 2, 3 ou 4 cases puis faire l'effet de la case en
                question.
                <br/>
                <br/>
                Vous pouvez aussi, à la place, jouer une carte Action que vous avez en main.
                <br/>
                <br/>
                Vous pouvez également payer 2 Papyrus pour piocher autant de fois que vous le souhaitez, avant de jouer
                normalement.
                <br/>
                <br/>
                Vous pouvez enfin payer 3 Fer pour rejouer après votre action. Attention, vous devez le faire avant de jouer.
                <br/>
            </div>
            <h2>
                Les différentes cases
            </h2>
            {
                rules.map((rule, i) => <div key={"rule" + i} style={styleRule}>
                <img style={styleRuleCase} src={"/public/heliopolis/" + rule.img + ".png"}/>
                    <div style={styleRuleText}>
                        {rule.rule}
                    </div>
                </div>)
            }
            <h2>
                Les autres symboles
            </h2>
            {
                otherRules.map((rule , i)=> <div key={"otherrule" + i} style={styleRule}>
                    <img style={styleRuleCase} src={"/public/heliopolis/" + rule.img + ".png"}/>
                    <div style={styleRuleText}>
                        {rule.rule}
                    </div>
                </div>)
            }
        </div>
    }

    return (
        <div style={{width: "100%", position: 'relative'}}>
            <div className="board-game-home-top-bar" style={{height: "8vh"}}>
                {
                    consults.map(c => <div key={c}
                                           className={"board-game-home-top-button board-game-home-top-button-" + (consult === c ? "active" : "inactive")}
                                           onClick={() => {
                                               setConsult(c)
                                           }}>
                        {c}
                    </div>)
                }
            </div>
            <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
                {
                    compConsult
                }
            </Box>
        </div>
    )

};

export default ConsultScreen;
