import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";


export const PickFromRiverState = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });


    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];


    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez vous l'action " + state.action.name}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                C'est à vous de jouer !
            </h2>
            <div>
                Prendre quelle carte gratuitement ?
            </div>
            <div style={{
                display: "-webkit-inline-box",
                position: "absolute",
                left: "10%",
                overflow: "scroll",
                marginTop: '24px',
                width: "80%"
            }}>
                {
                    actions.map((action, i) => <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '30vw',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                                                            key={action.cardId}>
                        <img style={{width: '100%', height: 'auto'}}
                             src={"/public/heliopolis/cards/card_" + action.cardId + ".png"}/>
                        <Button variant={"contained"} onClick={() => setState(state => ({...state, action, show: true}))}>
                            Choisir
                        </Button>
                    </div>)
                }

            </div>

            <div style={{height: "75vh"}}>

            </div>
        </div>
    )

};

export default PickFromRiverState;
