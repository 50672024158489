import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {dictCases} from "../utils";


export const MainState = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });

    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];

    function getActionComponent(action) {
        const style = {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        };
        if (action.class === "action.cases.ActionMine") {
            return <div style={style}>
                Produire {action.nbResource}
                <img style={{height: '40px', width: '40px'}}
                     src={"../../public/heliopolis/resource_" + action.resourceType.toLowerCase() + ".png"}/>
            </div>
        } else if (action.class === "action.actions.ActionSacrificePoints") {
            return <div style={style}>
                Gagner {action.nbPoints}
                <img style={{height: '40px', width: '40px'}}
                     src={"../../public/heliopolis/" + (action.points ? "victory" : "resource_papyrus") + ".png"}/>
            </div>
        } else if (action.class === "action.cases.ActionFaith") {
            return <div style={{...style, justifyContent: 'space-between'}}>
                <div style={{...style, textShadow: '#FFF 1px 0 10px', justifyContent: 'flex-start'}}>
                    Foi +{action.nbFaith}
                </div>
                <div style={style}>
                    -{action.goldCost}
                    <img style={{height: '40px', width: '40px', justifyContent: 'center', marginLeft: '8px'}}
                         src={"../../public/heliopolis/resource_gold.png"}/>
                </div>
                {
                    action.applyEffect ?
                        <div style={style}>
                            {action.nbResources}
                            <img style={{height: '40px', width: '40px', justifyContent: 'center', marginLeft: '8px'}}
                                 src={"../../public/heliopolis/" + action.image + ".png"}/>
                        </div> :
                        <div style={style}></div>
                }
            </div>
        } else if (action.class === "action.move.ActionMove") {
            return <div style={{...style, justifyContent: 'space-between'}}>
                <div style={{...style, textShadow: '#FFF 1px 0 10px', justifyContent: 'flex-start', textAlign: 'left'}}>
                    Avancer<br/> de {action.nbCases} cases
                </div>
                <div style={{...style, flexDirection: 'column'}}>
                    <img src={"/public/heliopolis/case_" + action.caseTypes.toLowerCase() + ".png"}
                         style={{height: '60%', width: 'auto'}}/>
                    <span>{dictCases[action.caseTypes]}</span>
                </div>
                {
                    action.barracksUserId ?
                        <div
                            style={{...style, flexDirection: 'column', justifyContent: 'center', position: 'relative'}}>
                            <img src={"/public/heliopolis/case_barracks.png"}
                                 style={{height: '100%', width: 'auto', position: 'absolute', top: 0}}/>
                            <span
                                style={{zIndex: 1000}}>Caserne : {props.state.players[action.barracksUserId].username}</span>
                        </div> :
                        <div style={{...style, flexDirection: 'column'}}>
                        </div>
                }
            </div>
        }

        return <div style={style}>
            {action.name}
        </div>;
    }

    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            position: "relative",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez vous l'action " + state.action.name}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                C'est à vous de jouer !
            </h2>
            <div>
                Que voulez-vous faire ?
            </div>
            <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                {
                    actions.filter(a => a.class !== "action.move.ActionPlayActionCard").map((a, i) => <Box
                        className={"board-game-build-action"}
                        style={{background: 'linear-gradient(to right, #201e4e 0%, #191b31 50%, #191b31 100%)'}}
                        sx={{
                            width: {xs: "120%", md: "100%"},
                            marginLeft: {xs: "-13%", md: "-3%"}
                        }}
                        key={i}>

                        {getActionComponent(a)}
                        <Button variant="contained" disabled={!a.ok}
                                onClick={() => setState(state => ({...state, action: a, show: true}))}>
                            Choisir
                        </Button>
                    </Box>)
                }
            </div>
            <div style={{
                display: "-webkit-inline-box",
                position: "absolute",
                left: "10%",
                overflow: "scroll",
                width: "80%"
            }}>
                {
                    actions.filter(a => a.class === "action.move.ActionPlayActionCard").map((action, i) => <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '30vw',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        key={action.card.id}>
                        <img style={{width: '100%', height: 'auto'}}
                             onClick={() => props.setCardId(action.card.id)}
                             src={"/public/heliopolis/cards/card_" + action.card.id + ".png"}/>
                        <Button variant={"contained"}
                                onClick={() => setState({...state, action, show: true})}
                                disabled={!action.ok}>
                            Jouer
                        </Button>
                    </div>)
                }
            </div>
            <div style={{height: "75vh"}}>

            </div>
        </div>
    )

};

export default MainState;
