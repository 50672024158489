import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";


export const ConstructionState = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });


    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];

    const [action, setAction] = useState(undefined);

    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez vous l'action " + state.action.name}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                C'est à vous de jouer !
            </h2>
            <div>
                Construire quel bâtiment ?
            </div>
            {
                action ?
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={"/public/heliopolis/cards/card_" + action.cardId + ".png"}
                             style={{width: '60%', height: 'auto', margin: "5px 1%"}}/>
                        <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '24px 0'}}>
                            <Button onClick={() => setAction(undefined)}>
                                Retour
                            </Button>
                            <Button variant="contained" disabled={!action.ok}
                                    onClick={() => setState(state => ({...state, action, show: true}))}>
                                Construire
                            </Button>
                        </div>
                    </div> :
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '12px',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {
                            actions.filter(a => a.class === "action.cases.ActionPlayBuildingCard").map((a, i) =>
                                <img onClick={() => setAction(a)}
                                     src={"/public/heliopolis/cards/card_" + a.cardId + ".png"}
                                     style={{opacity: a.ok ? 1 : 0.5, width: '30%', height: 'auto', margin: "5px 1%"}} key={a.cardId}/>
                            )
                        }
                    </div>
            }
            <Button variant="contained" disabled={false}
                    style={{margin: "24px auto"}}
                    onClick={() => setState(state => ({...state, action: actions[actions.length - 1], show: true}))}>
                Passer
            </Button>

            <div style={{height: action ? '10vh' : "75vh"}}>

            </div>
        </div>
    )

};

export default ConstructionState;
