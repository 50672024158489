import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";


export const SacrificeState = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });


    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];

    const [action, setAction] = useState({
        ...props.state.actions[props.userId][0],
        idPlayer: props.userId,
        cardIds: []
    });


    function isOk() {
        console.log(action.cardIds.sort((a,b) => a > b ? 1 : -1).join("-"));
        return actions.filter(a => a.cardIds).map(a => a.cardIds.join("-")).includes(action.cardIds.sort((a,b) => a > b ? 1 : -1).join("-"));
    }

    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez vous l'action " + state.action.name}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                Sacrifice !
            </h2>
            <div>
                Sélectionnez 3 symboles identiques ou 4 différents
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    ["anubis", "bastet", "horus", "apophis"].map(god => <div key={god}
                                                                             style={{
                                                                                 flex: 1,
                                                                                 display: 'flex',
                                                                                 justifyContent: 'center',
                                                                                 alignItems: 'center',
                                                                                 margin: "20px 0"
                                                                             }}
                    >
                        {0}
                        <img style={{height: '40px', width: 'auto'}} src={"/public/heliopolis/badge_" + god + ".png"}/>
                    </div>)
                }
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                {
                    props.state.players[props.userId].hand.map(cardId => <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: "30%",
                        margin: '0 1%'
                    }} key={cardId}>
                        <img
                            src={"/public/heliopolis/cards/card_" + cardId + ".png"}
                            style={{height: 'auto', width: '100%'}}/>
                        <div
                            onClick={() => {
                                setAction(a => (action.cardIds.indexOf(cardId) > -1 ? {
                                    ...a,
                                    cardIds: a.cardIds.filter(o => o !== cardId)
                                } : {...a, cardIds: [...a.cardIds, cardId]}))
                            }}
                            style={{
                                transition: 'all 0.5s',
                                opacity: action.cardIds.indexOf(cardId) > -1 ? 1 : 0,
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                                backgroundColor: 'rgba(31,112,234,0.32)'
                            }}>
                            Sacrifiée
                        </div>
                    </div>)
                }
            </div>
            <Button variant="contained" style={{margin: '12px'}} disabled={!isOk()}
                    onClick={() => setState(state => ({...state, action, show: true}))}>
                Sacrifier
            </Button>
            <div style={{margin: '12px'}}>
                <Button onClick={() => setState(st => ({...st, action: actions[actions.length - 1], show: true}))}
                        variant="contained">
                    Passer
                </Button>
            </div>

            <div style={{height: "75vh"}}>

            </div>
        </div>
    )

};

export default SacrificeState;
