
export const dictCases = {
    RUBY: "Rubis",
    MARKET: "Marché",
    PROD: "Production",
    TEMPLE: "Temple",
    CONSTRUCTION: "Construction",
    ECLIPSE: "Eclipse",
    BARRACKS: "Caserne",
    REPLAY: "Rejouer",
    SACRIFICE: "Sacrifice",
    START: "Départ",
};
